import React from 'react';
import DemoMethods from './DemoMethods';
import DemoRenderDiv from './DemoRenderDiv';
import './App.css';
import pkg from '../package.json';

const App = () => {
  return (
    <div className="App">
      <h1>BlendVision Player SDK</h1>
      <div className="main">
        <DemoMethods />
        <DemoRenderDiv />
      </div>
      <h5>version: {pkg.version}</h5>
    </div>
  );
};

export default App;
