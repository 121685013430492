import React, { useEffect, useRef, useState } from 'react';
import { Button, Box, TextField } from '@mui/material';
import Player from 'dist';
import 'demo/App.css';

const DemoMethods = () => {
  const [token, setToken] = useState<string>('');
  const playerRef = useRef<any>();
  const tokenRef = useRef<any>();
  const iframeRef = useRef<any>();

  useEffect(() => {
    playerRef.current = new Player('player', {
      token,
      locale: 'en',
      title: 'Player',
      event: {
        onEnded: () => {
          playerRef.current.seek(0);
          playerRef.current.play();
        },
      },
    });
    return () => {
      playerRef.current.destroy();
    };
  }, [token]);
  useEffect(() => {
    if (playerRef.current) {
      iframeRef.current = document.querySelector('#player iframe');
    }
  }, [playerRef.current]);

  const moveTitle = () => {
    if (iframeRef.current) {
      const src = iframeRef.current.src;
      const url = new URL(src);
      if (url.searchParams.get('titleSpacing')) {
        iframeRef.current.src = src.replace(`&titleSpacing=3`, '');
      } else {
        iframeRef.current.src += `&titleSpacing=3`;
      }
    }
  };
  const hideFullscreen = () => {
    if (iframeRef.current) {
      const src = iframeRef.current.src;
      const url = new URL(src);
      if (url.searchParams.get('disableFullscreen')) {
        iframeRef.current.src = src.replace(`&disableFullscreen=true`, '');
      } else {
        iframeRef.current.src += `&disableFullscreen=true`;
      }
    }
  };

  return (
    <>
      <div id="player" className="player" />
      <div className="right">
        <Box sx={{ p: 1 }}>
          <TextField
            label="token"
            sx={{ mb: 1 }}
            style={{ width: '100%' }}
            inputRef={tokenRef}
          />
          <Button
            variant="contained"
            onClick={() => {
              setToken(tokenRef.current.value);
            }}
          >
            Set token
          </Button>
        </Box>
        <Box sx={{ p: 1 }}>
          <Button
            sx={{ mx: 0.5, mb: 1 }}
            style={{ width: 84 }}
            variant="contained"
            onClick={() => playerRef.current.play()}
          >
            Play
          </Button>
          <Button
            sx={{ mx: 0.5, mb: 1 }}
            style={{ width: 84 }}
            variant="contained"
            onClick={() => playerRef.current.pause()}
          >
            Pause
          </Button>
          <Button
            sx={{ mx: 0.5, mb: 1 }}
            style={{ width: 84 }}
            variant="contained"
            onClick={() => playerRef.current.seek(60)}
            disabled
          >
            Seek
          </Button>
          <Button
            sx={{ mx: 0.5, mb: 1 }}
            style={{ width: 130 }}
            variant="contained"
            onClick={() => playerRef.current.rewind()}
            disabled
          >
            Rewind
          </Button>
          <Button
            sx={{ mx: 0.5, mb: 1 }}
            style={{ width: 130 }}
            variant="contained"
            onClick={() => playerRef.current.forward()}
            disabled
          >
            Forward
          </Button>{' '}
          <Button
            sx={{ mx: 0.5, mb: 1 }}
            style={{ width: 130 }}
            variant="contained"
            onClick={() => playerRef.current.setPlaybackRate(0.5)}
            disabled
          >
            Speed x0.5
          </Button>
          <Button
            sx={{ mx: 0.5, mb: 1 }}
            style={{ width: 130 }}
            variant="contained"
            onClick={() => playerRef.current.setPlaybackRate(1)}
            disabled
          >
            Speed x1
          </Button>
          <Button
            sx={{ mx: 0.5, mb: 1 }}
            style={{ width: 270 }}
            variant="contained"
            onClick={() => playerRef.current.setQuality(1080)}
          >
            Set Quality
          </Button>
          <Button
            sx={{ mx: 0.5, mb: 1 }}
            style={{ width: 130 }}
            variant="contained"
            onClick={() => playerRef.current.setVolume(0.5)}
          >
            Vol 50%
          </Button>
          <Button
            sx={{ mx: 0.5, mb: 1 }}
            style={{ width: 130 }}
            variant="contained"
            onClick={() => playerRef.current.setVolume(1)}
          >
            Vol 100%
          </Button>
          <Button
            sx={{ mx: 0.5, mb: 1 }}
            style={{ width: 270 }}
            variant="contained"
            onClick={() => playerRef.current.mute()}
          >
            Mute
          </Button>
          <Button
            sx={{ mx: 0.5, mb: 1 }}
            style={{ width: 270 }}
            variant="contained"
            onClick={moveTitle}
          >
            Move Title
          </Button>
          <Button
            sx={{ mx: 0.5, mb: 1 }}
            style={{ width: 270 }}
            variant="contained"
            onClick={hideFullscreen}
          >
            Hide/Show Fullscreen
          </Button>
        </Box>
      </div>
    </>
  );
};

export default DemoMethods;
