import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, TextField } from '@mui/material';
import Player from 'dist';
import 'demo/App.css';

const DemoRenderDiv = () => {
  const [token, setToken] = useState<string>('');
  const playerRef = useRef<any>();
  const tokenRef = useRef<any>();
  useEffect(() => {
    playerRef.current = new Player('id2', { token });
    return () => {
      playerRef.current.destroy();
    };
  }, [token]);
  return (
    <>
      <div id="id2" className="player" />
      <div className="right">
        <Box sx={{ p: 1 }}>
          <TextField
            label="token"
            sx={{ mb: 1 }}
            style={{ width: '100%' }}
            inputRef={tokenRef}
          />
          <Button
            variant="contained"
            onClick={() => {
              setToken(tokenRef.current.value);
            }}
          >
            Set token
          </Button>
        </Box>
        <Button
          sx={{ mx: 0.5, mb: 1 }}
          style={{ width: 84 }}
          variant="contained"
          onClick={() => playerRef.current.pause()}
        >
          Pause
        </Button>
      </div>
    </>
  );
};

export default DemoRenderDiv;
